import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabList } from "@CabComponents/CabList";
import { CabModal } from "@CabComponents/CabModal";
import { Box } from "@mui/material";
import { IoClose } from 'react-icons/io5';
import { DateTime } from "luxon";
import { ReactElement } from "react";
import { InvalidParticipantVotes } from "../../../store/schedule/types";
import { getLocalTimeZoneName, TimeZone } from "../../../utils/scheduleUtils";



export type PollUpdateModalProps = {
  open: boolean;
  pollUpdateHandlerAccept: () => void
  pollUpdateHandlerReject: () => void
  invalidMeetingSlots: InvalidParticipantVotes
  timezone?: TimeZone
};

const modalHeight = 500;

const formatSlot = (startDate: DateTime, endDate: DateTime) => {
  const timezone = getLocalTimeZoneName();
  const startDateFormatted = startDate.setZone(timezone).toFormat('ccc M/d/yyyy h:mma').toLowerCase();
  const endDateFormatted = endDate.setZone(timezone).toFormat('h:mma').toLowerCase();
  return startDateFormatted[0].toUpperCase() + startDateFormatted.slice(1) + '-' + endDateFormatted;
};


const PollUpdateModal = (
  {
    open, pollUpdateHandlerAccept, pollUpdateHandlerReject,
    invalidMeetingSlots, timezone
  }: PollUpdateModalProps
): ReactElement => {
  return (
    <CabModal
      title="Confirm This Change"
      isAlert open={open} sx={{ "& .MuiDialogContent-root": { height: modalHeight, overflow: "hidden" } }}
      text={`Some participants have already voted for times that would be removed
        by this change. By editing this poll, the following responses will no longer be valid:`}
      actionButtons={<>
        <CabButton buttonType="secondary" sx={{ marginRight: 1 }} onClick={() => pollUpdateHandlerReject()}>
          Cancel
        </CabButton>
        <CabButton onClick={() => pollUpdateHandlerAccept()}>
          Accept
        </CabButton>
      </>}
    >
      <Box sx={{ maxHeight: modalHeight - 140, overflow: 'auto' }}>
        {Object.entries(invalidMeetingSlots).filter(([key, value]) => value.invalidVotes).map(([key, value]) => (
          <Box key={key} sx={{ marginTop: 2 }}>
            <Box sx={{ marginBottom: 1, fontWeight: 700 }}>{value?.name}</Box>
            <CabList
              items={value.invalidVotes
                ? value.invalidVotes.toSorted(
                  (a, b) =>
                    DateTime.fromISO(a.start_date).toMillis() - DateTime.fromISO(b.start_date).toMillis()
                ).map((res, idx) => ({
                  value: idx,
                  icon: <span>{DateTime.fromISO(res.start_date).setZone(timezone?.name || "").toFormat('ZZZZ')}</span>,
                  label: (
                    <Box display="flex" justifyContent="space-between">
                      <span>
                        {formatSlot(
                          DateTime.fromISO(res.start_date),
                          DateTime.fromISO(res.end_date),
                        )}
                      </span>
                      <CabIcon
                        Icon={IoClose}
                        color="danger"
                        alt='removed'
                        sx={{ marginTop: '2px', fontSize: 18 }}
                      />
                    </Box>
                  ),
                  editable: false,
                }))
                : []
              }
            />
            <hr />
          </Box>
        ))}
      </Box>
    </CabModal>
  );
};

export default PollUpdateModal;
